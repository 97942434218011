// Libraries
import _ from 'lodash';

// Supermove
import {List} from '@supermove/utils';

const ADMIN = 'admin' as const;
const CONTRACTOR = 'CONTRACTOR' as const;
const CUSTOMER = 'customer' as const;
const EMPLOYEE = 'employee' as const;
const INTEGRATION = 'INTEGRATION' as const;
const MANAGER = 'manager' as const;
const OPS_ADMIN = 'OPS_ADMIN' as const;
const SALES = 'SALES' as const; // Copilot, sales app role
const SALESPERSON = 'salesperson' as const;
const SUPER_ADMIN = 'super' as const;

const ROLES = [
  ADMIN,
  CUSTOMER,
  EMPLOYEE,
  MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
  CONTRACTOR,
  OPS_ADMIN,
  SALES,
] as const;

// Types
export type RoleType = (typeof ROLES)[number];
export type RoleArgument = RoleType | undefined;
type DropdownOption = {label: string; value: RoleType};
type DropdownOptionWithDescription = DropdownOption & {description: string};

// Role groups
const SALESPERSON_ROLES: readonly RoleType[] = [SALESPERSON] as const;
const STAFF_ADMIN_ROLES: readonly RoleType[] = [ADMIN] as const;
const ADMIN_ROLES: readonly RoleType[] = [OPS_ADMIN, ...STAFF_ADMIN_ROLES] as const;
const MANAGER_ROLES: readonly RoleType[] = [MANAGER, ...ADMIN_ROLES] as const;
const OFFICE_ROLES: readonly RoleType[] = [SALES, ...SALESPERSON_ROLES, ...MANAGER_ROLES] as const;
const NON_ADMIN_OFFICE_ROLES: readonly RoleType[] = [MANAGER, SALES, ...SALESPERSON_ROLES] as const;

// Role groups with SUPER_ADMIN
const STAFF_ADMIN_ROLES_PLUS_SUPER: readonly RoleType[] = [
  SUPER_ADMIN,
  ...STAFF_ADMIN_ROLES,
] as const;
const ADMIN_ROLES_PLUS_SUPER: readonly RoleType[] = [SUPER_ADMIN, ...ADMIN_ROLES] as const;
const MANAGER_ROLES_PLUS_SUPER: readonly RoleType[] = [SUPER_ADMIN, ...MANAGER_ROLES] as const;
const OFFICE_ROLES_PLUS_SUPER: readonly RoleType[] = [SUPER_ADMIN, ...OFFICE_ROLES] as const;

// Contains the display order for roles when showing compensation
const ROLES_FOR_COMPENSATION: readonly RoleType[] = [
  ...SALESPERSON_ROLES,
  CONTRACTOR,
  EMPLOYEE,
] as const;

const getRolesForCompensation = (): RoleType[] => {
  // In addition to ROLES_FOR_COMPENSATION we want to include all the other roles
  // to cover edge cases and for future proofing.
  const nonCompensationRoles = _.filter(ROLES, (role) => !_.includes(ROLES_FOR_COMPENSATION, role));
  return [...ROLES_FOR_COMPENSATION, ...nonCompensationRoles];
};

const CUSTOM_DISPLAY_ROLE_MAP: Partial<Record<RoleType, string | undefined>> = {
  [EMPLOYEE]: 'Mover',
  [OPS_ADMIN]: 'Operations Admin',
  [SUPER_ADMIN]: 'Super Admin',
};

const getDisplayRole = (userRole: RoleType): string => {
  return CUSTOM_DISPLAY_ROLE_MAP[userRole] || _.startCase(_.lowerCase(userRole));
};

const getDropdownOptions = ({viewerRole}: {viewerRole: RoleType}): DropdownOption[] => {
  return [
    ...List.insertIf(viewerRole === SUPER_ADMIN, {
      label: 'Super Admin - All permissions including internal features',
      value: SUPER_ADMIN,
    }),
    ...List.insertIf(STAFF_ADMIN_ROLES_PLUS_SUPER.includes(viewerRole), {
      label: 'Admin - All permissions',
      value: ADMIN,
    }),
    {
      label: 'Operations Admin - All permissions except accounting',
      value: OPS_ADMIN,
    },
    {
      label: 'Manager - View all projects',
      value: MANAGER,
    },
    {
      label: 'Salesperson - View only assigned projects',
      value: SALESPERSON,
    },
  ];
};

const getDropdownOptionsWithDescription = ({
  viewerRole,
}: {
  viewerRole: RoleType;
}): DropdownOptionWithDescription[] => {
  return [
    ...List.insertIf(viewerRole === SUPER_ADMIN, {
      value: SUPER_ADMIN,
      label: getDisplayRole(SUPER_ADMIN),
      description: 'All permissions including internal features',
    }),
    ...List.insertIf(STAFF_ADMIN_ROLES_PLUS_SUPER.includes(viewerRole), {
      value: ADMIN,
      label: getDisplayRole(ADMIN),
      description: 'All permissions',
    }),
    {
      value: OPS_ADMIN,
      label: getDisplayRole(OPS_ADMIN),
      description: 'All permissions except accounting',
    },
    {
      value: MANAGER,
      label: getDisplayRole(MANAGER),
      description: 'View all projects',
    },
    {
      value: SALESPERSON,
      label: getDisplayRole(SALESPERSON),
      description: 'View only assigned projects',
    },
  ];
};

const getCopilotDropdownOptions = (): DropdownOption[] => {
  return [
    {label: 'Sales', value: SALES},
    {label: 'Admin', value: ADMIN},
  ];
};

const getIsSalesperson = (userRole: RoleArgument): boolean =>
  userRole ? SALESPERSON_ROLES.includes(userRole) : false;
const getIsStaffAdmin = (userRole?: RoleArgument): boolean =>
  userRole ? STAFF_ADMIN_ROLES_PLUS_SUPER.includes(userRole) : false;

const hasOfficePermissions = (userRole: RoleArgument): boolean =>
  userRole ? OFFICE_ROLES_PLUS_SUPER.includes(userRole) : false;
const hasManagerPermissions = (userRole: RoleArgument) =>
  userRole ? MANAGER_ROLES_PLUS_SUPER.includes(userRole) : false;
const hasAdminPermissions = (userRole: RoleArgument) =>
  userRole ? ADMIN_ROLES_PLUS_SUPER.includes(userRole) : false;
const hasStaffAdminPermissions = (userRole: RoleArgument) =>
  userRole ? STAFF_ADMIN_ROLES_PLUS_SUPER.includes(userRole) : false;

const getJobActionDisabledTooltip = (userRole?: RoleArgument) =>
  getIsStaffAdmin(userRole)
    ? 'This job is finalized. To make edits, unfinalize it.'
    : 'Contact an admin to unfinalize this job.';

// Wraps each role in double quotes to be used as arguments in GraphQL.
const getGraphqlArgs = (roles: RoleType[]) => roles.map((role) => `"${role}"`);

const UserRole = {
  ADMIN,
  CONTRACTOR,
  CUSTOMER,
  EMPLOYEE,
  INTEGRATION,
  MANAGER,
  OPS_ADMIN,
  SALESPERSON,
  SUPER_ADMIN,

  ROLES,
  ADMIN_ROLES,
  MANAGER_ROLES,
  OFFICE_ROLES,
  NON_ADMIN_OFFICE_ROLES,
  STAFF_ADMIN_ROLES_PLUS_SUPER,
  ADMIN_ROLES_PLUS_SUPER,
  MANAGER_ROLES_PLUS_SUPER,
  OFFICE_ROLES_PLUS_SUPER,

  getDisplayRole,
  getRolesForCompensation,
  getDropdownOptions,
  getCopilotDropdownOptions,
  getDropdownOptionsWithDescription,
  getIsSalesperson,
  getIsStaffAdmin,
  getGraphqlArgs,
  getJobActionDisabledTooltip,

  // Permissions
  hasOfficePermissions,
  hasManagerPermissions,
  hasAdminPermissions,
  hasStaffAdminPermissions,
} as const;

export default UserRole;
