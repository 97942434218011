const MISSED = 'MISSED';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETE = 'COMPLETE';
const ERROR = 'ERROR';
const OUTGOING_RINGING_TO_NUMBER = 'OUTGOING_RINGING_TO_NUMBER';
const OUTGOING_RINGING_FROM_NUMBER = 'OUTGOING_RINGING_FROM_NUMBER';
const CANCELLED = 'CANCELLED';

const getCallStatusDropdownOptions = () => [
  {value: MISSED, label: 'Missed'},
  {value: IN_PROGRESS, label: 'In Progress'},
  {value: COMPLETE, label: 'Complete'},
  {value: ERROR, label: 'Error'},
  {value: OUTGOING_RINGING_TO_NUMBER, label: 'Dialing Customer'},
  {value: OUTGOING_RINGING_FROM_NUMBER, label: 'Dialing Salesperson'},
  {value: CANCELLED, label: 'Cancelled'},
];

// Groups of statuses, we can expect these to have similar data states related to call start time, end time, etc.
const DIALING_CALL_STATUSES: CallStatusType[] = [
  OUTGOING_RINGING_TO_NUMBER,
  OUTGOING_RINGING_FROM_NUMBER,
];
const COMPLETE_CALL_STATUSES: CallStatusType[] = [MISSED, COMPLETE, ERROR, CANCELLED];

const CallStatuses = {
  MISSED,
  IN_PROGRESS,
  COMPLETE,
  ERROR,
  OUTGOING_RINGING_TO_NUMBER,
  OUTGOING_RINGING_FROM_NUMBER,
  CANCELLED,
} as const;

export type CallStatusType = (typeof CallStatuses)[keyof typeof CallStatuses];

const CallStatus = {
  ...CallStatuses,

  // Groups of statuses
  DIALING_CALL_STATUSES,
  COMPLETE_CALL_STATUSES,
  getCallStatusDropdownOptions,
};

export default CallStatus;
