// Libraries
import React from 'react';

// Supermove
import {Styled, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

// App
import Text from '@shared/design/components/Text';
import TextTooltip, {Placement} from '@shared/design/components/TextTooltip';

const AvatarContainer = Styled.View<{desktop: boolean; isLogo: boolean}>`
  height: ${({desktop}) => (desktop ? '28' : '32')}px;
  min-width: ${({desktop}) => (desktop ? '28' : '32')}px;
  padding-horizontal: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({isLogo}) => (isLogo ? colors.black : colors.gray.border)}
  border-radius: 4px;
`;

type UserAvatarProps = {
  user?: UserModel;
  tooltipPlacement?: Placement;
  TextComponent?: React.FC<{children: React.ReactNode; style?: any}>;
  logoSize?: number;
  style?: ViewStyleProp;
};

const UserAvatar = ({
  user,
  tooltipPlacement = 'bottom',
  style,
  TextComponent = Text.MicroLabel,
  logoSize,
}: UserAvatarProps) => {
  const responsive = useResponsive();
  return (
    <TextTooltip
      text={user ? user.fullName : 'Supermove'}
      placement={tooltipPlacement}
      isEnabledMobileToast={false}
    >
      <AvatarContainer style={style} isLogo={!user} {...responsive}>
        {user ? (
          <TextComponent style={{color: colors.gray.primary}}>{user.initials}</TextComponent>
        ) : (
          <SupermoveLogo size={logoSize || (responsive.desktop ? 10 : 12)} />
        )}
      </AvatarContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAvatar.fragment = gql`
  fragment UserAvatar on User {
    id
    initials
    fullName
  }
`;

export default UserAvatar;
